import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { db } from "../configs/Firebase";
import { FaCircle, FaRegUser } from "react-icons/fa";

const UsersComponent = ({ users, setReceiverData, currentUserId }) => {
    const [unreadMessages, setUnreadMessages] = useState({});

    useEffect(() => {
        if (currentUserId) {
            const unsubscribe = users.forEach(user => {
                const messagesRef = collection(db, "users", currentUserId, "chatUsers", user.userId, "messages");
                const unreadQuery = query(messagesRef, where("read", "==", false), where("messageUserId", "==", user.userId));
                onSnapshot(unreadQuery, (snapshot) => {
                    setUnreadMessages(prev => ({
                        ...prev,
                        [user.userId]: !snapshot.empty
                    }));
                });
            });

            return () => {
                unsubscribe && unsubscribe.forEach(unsub => unsub());
            };
        }
    }, [currentUserId, users]);

    return (
        <div>
            {users.map((user) => (
                <div
                    key={user.userId}
                    onClick={() => setReceiverData(user)}
                    className="flex items-center bg-gray-20 my-2 rounded space-x-4 p-2 hover:bg-white cursor-pointer"
                >
                    <div className="flex-1">
                        <div className="flex justify-between">
                            <span className="font-bold text-gray-700 flex items-center"><FaRegUser className="mr-3" color={unreadMessages[user.userId]? 'red': 'gray' }/> {user.username}</span>
                            {unreadMessages[user.userId] && (
                                <span className="text-red-500 font-bold">
                                    <FaCircle color="red"  size={8}/>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UsersComponent;
