import { useNavigate } from "react-router-dom";
import 'chart.js/auto';
import { Fragment, useEffect, useState } from "react";
import { getElement } from "../../configs/http-connect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAge } from "../../utilities/calcul";
import ProfileDetail from "../../components/ProfileDetail";
import MenuHeader from "../../components/MenuHeader";
import { MdOutlineInfo } from "react-icons/md";
import { MdOutlineLockPerson } from "react-icons/md";
import { matchLevel } from "../../utilities/matchLevel";
import { translate } from "../../utilities/lng";
import PulseLoader from "react-spinners/PulseLoader";
import { FaArrowRight } from "react-icons/fa";


function MatchSpace({ toggleRightMenu, startChat, myProfile }) {
    const navigate = useNavigate();
    const [matchs, setMatchs] = useState([]);
    const [readyMatchs, setReadyMatchs] = useState([]);
    const [unblockedProfiles, setUnblockedProfiles] = useState([]);
    const [profile, setProfile] = useState();
    const [unblocked, setUnblocked] = useState(false);
    const [displayInfos, setDisplayInfos] = useState(true);
    const [displayDetails, setDisplayDetails] = useState(false);
    const [activeTab, setActiveTab] = useState("profiles");

    const [loading, setLoading] = useState(false);

    const openDetail = (profile, unblocked = false) => {
        setUnblocked(unblocked);
        setProfile(profile);
        setDisplayDetails(true);
    }

    const getMatchedProfiles = () => {
        getElement('candidate/match_profile/').then(res => {
            setMatchs(res.data);
            console.log(' res.data ', res.data)
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    const getReadyMatchedProfiles = () => {
        getElement('candidate/profile_matched/').then(res => {
            setReadyMatchs(res.data);
            if (res.data.length > 0) {
                setDisplayInfos(false);
            }
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    const getUnblockedProfiles = () => {
        getElement('candidate/profile_unblocked/').then(res => {
            setUnblockedProfiles(res.data);
            console.log(' res.data ', res.data)
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    const toggleInfos = () => {
        setDisplayInfos(!displayInfos);
    }

    const openInNewTab = (url) => {
        setTimeout(() => {
            window.open(url, "_blank");
        });
    }

    const getActiveMatchCheckoutUrl = () => {
        setLoading(true);
        getElement('candidate/checkout_url_addmatch/').then(res => {
            openInNewTab(res.data.url);
        }).catch(err => {
            console.log('Error ::: ', err);
            setLoading(false);
        });
    }

    const activeNewMatch = () => {
        getActiveMatchCheckoutUrl()
    }


    useEffect(() => {
        getReadyMatchedProfiles();
        getUnblockedProfiles();
        getMatchedProfiles();
    }, [navigate]);

    return (
        <div className="bg-gray-100 h-screen pt-0 lg:p-10 lg:pt-0 w-full overflow-auto">
            <div className="w-full md:flex md:justify-center">
                <div className="md:w-[620px] p-3">
                    <MenuHeader toggleRightMenu={toggleRightMenu} />

                    {displayDetails ? (
                        <ProfileDetail
                            profile={profile}
                            setDisplayDetails={setDisplayDetails}
                            startChat={startChat}
                            unblocked={unblocked}
                        />
                    ) : (
                        <div>

                            <div className="flex justify-between mb-6">
                                <button
                                    className={`w-1/2 py-2 px-4 text-center font-semibold ${activeTab === "profiles" ? "text-white bg-red-500" : "text-red-500 bg-white"} rounded-l-lg border border-red-500`}
                                    onClick={() => setActiveTab("profiles")}
                                >
                                    Profiles
                                </button>
                                <button
                                    className={`w-1/2 py-2 px-4 text-center font-semibold ${activeTab === "matches" ? "text-white bg-red-500" : "text-red-500 bg-white"} rounded-r-lg border border-red-500`}
                                    onClick={() => setActiveTab("matches")}
                                >
                                    Mes matchs
                                </button>
                            </div>

                            {/* Partie 1 : Profils débloqués et matchs en cours */}
                            {activeTab === 'matches' && (
                                <div className="mb-6">
                                    {unblockedProfiles.length > 0 && (
                                        <Fragment>
                                            <span className="my-4 text-xl">Profils débloqués</span>
                                            <div className="mt-4 mb-4">
                                                {unblockedProfiles.map((profile, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-gray-50 p-4 shadow rounded-lg mb-2 cursor-pointer"
                                                        onClick={() => openDetail(profile, true)}
                                                    >
                                                        <p className="text-gray-700">{profile.name} {getAge(profile.birth_date)} ans</p>
                                                        <p className="text-gray-400">{profile.residence_country} {profile.residence_city}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Fragment>
                                    )}

                                    {readyMatchs.length > 0 && (
                                        <Fragment>
                                            <span className="my-4 text-xl">Mes matchs en cours</span>
                                            <div className="mt-4 mb-4">
                                                {readyMatchs.map((match, index) => (
                                                    <div
                                                        key={index}
                                                        className="bg-gray-50 p-4 shadow rounded-lg mb-2 cursor-pointer"
                                                        onClick={() => openDetail(match)}
                                                    >
                                                        <p className="text-gray-700">{match.name} {getAge(match.birth_date)} ans</p>
                                                        <p className="text-gray-400">{match.residence_country} {match.residence_city}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            )}

                            {/* Partie 2 : Sélection de profils */}
                            {activeTab === 'profiles' && (<Fragment>

                                <div className="my-4">
                                    <p className="inline-flex items-baseline items-center text-xl">
                                        <span>Choisissez les profils qui vous intéressent. </span>
                                        <MdOutlineInfo className="mx-1" color="green" onClick={toggleInfos} />
                                    </p>
                                    {myProfile.formula === 'basic' && (
                                        <p>
                                            <small className="text-red-500">
                                                Cette formule ne vous permet pas d'initier des matchs
                                            </small>
                                        </p>
                                    )}
                                    {myProfile.formula === 'standard' && (
                                        <div>
                                            <small className="text-gray">
                                                Vous pouvez sélectionner jusqu'à <span className="font-bold text-red-500"> {myProfile.max_match} profils </span> sur plusieurs jours.
                                                {displayInfos && <Fragment> Consultez les détails de chaque profil et effectuez un match
                                                    si le profil vous intéresse. Revenez régulièrement pour découvrir
                                                    de nouveaux profils à sélectionner.</Fragment>}
                                            </small>
                                        </div>
                                    )}
                                </div>

                                <button type="button" onClick={activeNewMatch} className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                                    Activer 10 nouveaux matchs (25€) {loading ? <PulseLoader color='red' className="ml-4" /> : <FaArrowRight color="red" className="ml-4" />}
                                </button>

                                <div className="mt-4 mb-4">
                                    {matchs.length > 0 ? (
                                        matchs.map((match, index) => (
                                            <div
                                                key={index}
                                                className="bg-gray-50 p-4 shadow rounded-lg mb-2 cursor-pointer"
                                                onClick={() => openDetail(match)}
                                            >
                                                <p className="text-gray-700 flex items-center">
                                                    <span className="text-lg">{match.name} {getAge(match.birth_date)}</span>
                                                    {!match.payed && <MdOutlineLockPerson color="orange" />}
                                                    <small className="mx-2">{matchLevel(match.match_percentage)}</small>
                                                </p>
                                                <p className="text-gray-400">{match.residence_country} {match.residence_city}</p>
                                                <div className="flex flex-wrap gap-1 rounded my-1 max-w-full">
                                                    {Object.entries(match.criteria).map(([key, value], index) => (
                                                        value > 30 && (
                                                            <div
                                                                key={index}
                                                                className="cursor-pointer px-2 border border-red-200 rounded-md bg-transparent text-neutral-600 text-sm"
                                                            >
                                                                {translate[key]}
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                                <p className="text-gray-500 text-sm">
                                                    Bio : {match?.bio?.substring(0, 80) ?? '_' + "..."}
                                                </p>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Chargement des matchs...</p>
                                    )}
                                </div>
                            </Fragment>)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MatchSpace;
